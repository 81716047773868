import React, { useEffect, useState } from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { Trans, useTranslation } from "react-i18next";
import {
  BoldLink,
  Box,
  BoxContainer,
  Container,
  HeadingContainer,
  LogoContainer,
  LogoOuterContainer,
} from "styles/add-live-chat.style";
import { PageContainer } from "styles/global.style";
import { Title } from "components/core/Typography/Title";
import Heading from "components/core/Typography/Heading";
import Button from "components/core/Button";
import Fade from "components/core/Animation/extended/Fade";
import * as queryString from "query-string";
import If from "components/core/If";
import StaticSvg from "components/core/StaticSvg";

declare var window: {
  customerly: any;
  location: any;
  history: History;
  close: Function;
};

export default ({ location }: any) => {
  const { t } = useTranslation();
  const { partner } = queryString.parse(location.search);
  const [isWindow, setIsWindow] = useState<boolean>(false);

  useEffect(() => {
    if (window.customerly) {
      window.customerly.hide();
    }

    setIsWindow(true);
  });

  return (
    <GlobalWrapper location={location}>
      <SEO
        title={"Welcome in Customerly!"}
        description={
          "Add the free live chat on your website to get more sales and to make your customers satisfied."
        }
        withoutTitleSuffix={true}
      />
      <Container>
        <PageContainer>
          <LogoOuterContainer>
            <LogoContainer>
              <StaticSvg alt={"Customerly"} src={"logo/wide-white.svg"} />
            </LogoContainer>
          </LogoOuterContainer>

          <Fade direction={"up"}>
            <HeadingContainer>
              <Heading level={1} weight={"600"}>
                {t("pages.add-live-chat.heading.title")}
              </Heading>
              <p>{t("pages.add-live-chat.heading.whoWeAre")}</p>
              <p>{t("pages.add-live-chat.heading.direction")}</p>
            </HeadingContainer>
          </Fade>

          <BoxContainer>
            <Fade direction={"up"} delay={400}>
              <Box>
                <If condition={partner !== "" && partner !== undefined}>
                  <Title>
                    <Trans
                      i18nKey="pages.add-live-chat.support.titleWithCompany"
                      values={{ partner: partner }}
                    >
                      I'm looking for support with
                      {isWindow && (
                        <BoldLink href={`http://${partner}`}>
                          {{ partner }}
                        </BoldLink>
                      )}
                    </Trans>
                  </Title>
                </If>
                <If condition={partner === "" || partner === undefined}>
                  <Title>{t("pages.add-live-chat.support.title")}</Title>
                </If>
                <p>{t("pages.add-live-chat.support.description")}</p>
                <Button
                  fullWidth
                  onClick={() => {
                    if (window) {
                      window.history.back(); // Go back in browser history
                      window.close(); // If it's not going back close the page because maybe it's coming from target="_blank"
                    }
                  }}
                >
                  {t("pages.add-live-chat.support.cta")}
                </Button>
              </Box>
            </Fade>
          </BoxContainer>

          <BoxContainer>
            <Fade direction={"up"} delay={800}>
              <Box>
                <Title>
                  <Trans i18nKey="pages.add-live-chat.explore.title">
                    I'm curious about <b>Customerly</b>
                  </Trans>
                </Title>
                <p>{t("pages.add-live-chat.explore.description")}</p>
                <Button
                  fullWidth
                  as={"a"}
                  href={`/live-chat/${location.search}`}
                >
                  {t("pages.add-live-chat.explore.cta")}
                </Button>
              </Box>
            </Fade>
          </BoxContainer>
        </PageContainer>
      </Container>
    </GlobalWrapper>
  );
};
